import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { Link, injectIntl } from "gatsby-plugin-intl"
import { genderReverse } from "../../../utils/lyneUphelpers"

const LyneGenderSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 10px;
`

const LyneGenderChooseText = styled.p`
  font-family: "Museo";
  font-weight: bold;
  font-size: 16px;
  line-height: 1.2;
  margin: 0px;
`

const LyneNoGenderText = styled.p`
  font-family: "Gotham Book";
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  margin: 0px;
  color: ${({ error }) => (error ? "#EF2C2C" : "#262626")};
  max-width: 420px;
`

const LyneGenderSectionBox = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0px;
`

const LyneGenderBox = styled(Link)`
  display: flex;
  width: 100%;
  max-width: 170px;
  padding: 3px;
  cursor: pointer;
  margin: 0px 3px;

  :hover {
    border: 2px solid #5db9bc;
    border-radius: 5px;
  }

  @media (max-width: 600px) {
    max-width: calc(50% - 6px);
  }
`

const LyneGenderBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: inherit;
  border: 1px solid #262626;
  border-radius: 5px;
  font-weight: 500;
  overflow: hidden;
`

const LyneGenderTextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 9px 9px 5px;

  @media (max-width: 360px) {
    padding: 9px 6px 5px;
  }
`

const LyneGenderText = styled.p`
  font-family: "Gotham Book";
  font-size: 14px;
  line-height: 1.2;
  margin: 0px;

  @media (max-width: 360px) {
    font-size: 13.5px;
  }
`

const LyneGenderImage = styled.img`
  display: flex;
  object-fit: contain;
  margin: 0px;
  width: 100%;
  aspect-ratio: 16 / 7;
`

const SolutionGenderRedirectSection = ({ intl, data, gender, error, id }) => {
  const generateRedirectionUrl = index => {
    if (typeof index === "undefined" || index === null) return ""

    if (index === 0)
      return "/shop/solutions-textiles/tshirt-discret/?Gender=Female&Collar=V&Color=Rose%20poudré&Size=&Offer=Seul"
    else if (index === 1)
      return "/shop/solutions-textiles/tshirt-discret/?Gender=Male&Collar=V&Color=Sable&Size=&Offer=Seul"
    else return "/shop/solutions-textiles/"
  }
  return (
    data && (
      <LyneGenderSectionWrapper id={id}>
        <LyneGenderChooseText
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: "lyne_selected_gender_text",
            }),
          }}
        />
        <LyneGenderSectionBox>
          {data.map(
            (item, index) =>
              item && (
                <LyneGenderBox
                  to={generateRedirectionUrl(index)}
                  key={index.toString()}
                >
                  <LyneGenderBoxWrapper>
                    <LyneGenderTextBox>
                      <LyneGenderText
                        dangerouslySetInnerHTML={{
                          __html: index == 0 ? "Femme >" : "Homme >",
                        }}
                      />
                    </LyneGenderTextBox>
                    {!gender && (
                      <LyneGenderImage
                        className="lazyload"
                        data-src={
                          index == 0
                            ? "https://static.percko.com/uploads/1158b9ee-6f86-40c7-93c8-febdfa8114bd.jpg"
                            : "https://static.percko.com/uploads/c7b91aaf-9cca-4d92-8f35-a0730b9b3c17.jpg"
                        }
                      />
                    )}
                  </LyneGenderBoxWrapper>
                </LyneGenderBox>
              )
          )}
        </LyneGenderSectionBox>
        {!gender && (
          <LyneNoGenderText
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "lyne_no_gender_text",
              }),
            }}
            error={error}
          />
        )}
      </LyneGenderSectionWrapper>
    )
  )
}

export default injectIntl(SolutionGenderRedirectSection)
